<div class="transaction-main">
  <div class="transaction-header">
    <div class="transaction-title-header pa-5">
      <v-img src="@/assets/images/logo_blue.png" width="200" />
    </div>
  </div>
  <div class="transaction-banner-main">
    <div class="transaction-banner-text">
      <div class="transaction-banner-title">{{ form.title }}</div>
      <div class="transaction-banner-subtitle">{{ form.subtitle }}</div>
    </div>
    <div id="transaction-banner-cover" class="transaction-banner-cover"></div>
  </div>
  <div class="transaction-data">
    <div class="position-relative pa-5 h-auto">
      <loading v-model:active="overlay" :can-cancel="false" :is-full-page="false" background-color="#eeeeee" />
      <v-progress-linear v-model="process" color="warning"></v-progress-linear>
      <v-window v-model="step" :touch="{left: null, right: null}">
        <v-window-item>
          <ModulePay :form="form" v-on:stepone="stepone" />
        </v-window-item>
        <v-window-item>
          <ModuleData :form="form" v-on:steptow="steptwo" v-on:stepcero="stepcero" />
        </v-window-item>
        <v-window-item>
          <ModulePayment v-on:stepthree="stepthree" :transaction="transaction" v-on:stepcero="stepcero" />
        </v-window-item>
        <v-window-item>
          <ZonePse v-if="transaction.payment_method_type == 'PSE' && !isFinish" :donant="donant"
            :transaction="transaction" />
          <ZoneTransfer v-if="transaction.payment_method_type == 'BANCOLOMBIA_TRANSFER' && !isFinish" :donant="donant"
            :transaction="transaction" />
          <ZoneNequi v-if="transaction.payment_method_type == 'NEQUI' && !isFinish" :donant="donant"
            :transaction="transaction" />
          <ZoneCard v-if="transaction.payment_method_type == 'CARD' && !isFinish" :donant="donant"
            :transaction="transaction" />
        </v-window-item>
      </v-window>
    </div>
  </div>
  <div class="transaction-footer">
    <v-container>
      <div>
        <v-img src="@/assets/images/logo_white.png" width="227" />
      </div>
      <div class="text-caption my-1">Somos auditados por:</div>
      <div>
        <v-img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAF0AAAAbCAYAAAAecIOyAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+9JREFUeNrsWWtIVFEQ3lVbw2SzCKI1SHrYn4KSKJGKjYzIJAsMJCqILP8URUZR9LAgKIIMIqg//akQekn0gkh3TZPINCsTMTGLCMNSw0e2urt9E2flMOy9193rbhZn4OPcM3Ned+7MnDnnWv1+v0VRdClGqUApXSldkVL6f0NxBvJNgE2q+4BvQC9Qa7Va+8KdGBt4Oor0QB1jnQ+x/3QUuRLrOsboYG3GoYgFf0BjDHp/G+T9ZhUZUkJCjXXQ5demDuASkBDmIovkwcLo72TrWSD4KUAJUAH0UDvWbzZwF6gKJjej9JEizsQ8U4ACYAkGyoS1fI+yl5K31Uv1n6JMAfJ0+pGHrBvL4UUmCisN5K7AUooIgk8WVgTs1rCAGHwQnwkLovDg5XzwXqJYGGkFmV2/2fBSKvVJA/qZe9sl+VqgjsldwOKRhBdU84AWSewFrgLT9MILH5PRXgN5kTQ2jfWYyd8C60cjvISVveDL16G4xth5YvJ8FPeDWCHFzueQZxsYwWkUJcAslmVtBhrpg0fYspehIC9axUTzgFLIC/5myniP1R1Y0ESUchZSA+wBygPfCzii88L0oQolVhNwAfgl6klAsc6a2oEWxmsRsb/DQN4u6hdFCCVqBfYDd6T257DO+KiHFw33Jtddw3gZQBKFBcafEyy8oDjGQkqq4Bey/g6d7IXznQbrdsppLJPtFOsn9En85VEPL4KmsvoQMJ/xngFdwBfGT9YYM0V6bkYYaxbPtaxdZoSiy2RWvyzWT0gYrUOlmc4bWb0amGDypeX+3Trt7BFS+qSxljLKrpSDIkdidcIqy8GfyZquBjxBhqgXGyunD9LzXIxHp0UPOxUTvY+QPr6yOu0vdRrrt5x8mrRowNtvd844VInqYCQsnQ5BtHtX0C7OPthxaePk3lANxbmFJyTTM6BlxQ3M6gowX6LYzAJEObs7hHVvEClsvoZ8K2RZAM3RKG3aAaN5LdZfKTzxDa3/qDtxV99gd43X5ylztZ1yh2q14V4DBOgWHSCk8aoN2mdo5emkYKDVoP9Zg2uANI1+r4Q8VUPeJeTFBvPvoHYHyywuwB9AtDZSSsEOkDWzE1u2sGotWqmT//eKTfKjRpMb5NUjOEPc1JE368lBh4OcQWT6c36Itdo+DY9psXaPZkw/A4xnvE7gHfACL9AT5KU68TVX4DFLXBHIRO77UDy7NZTSKqw2V9yTDOf8kD2Q6m3ACZajB2g74JIyrCHprEC0DXhCZwvp9rRKzE93OFuwhiviuiOWzfnoz05uc+z74fnc5PMPxdvjHbdDOlyqf6TRJ/UTQyldKV2RUrpSuiKl9H+LfgswANeu6rFd2edWAAAAAElFTkSuQmCC"
          width="93" />
      </div>
    </v-container>
  </div>
</div>