<template src="./views/TransactionClose.html"></template>
<script>
  import axios from 'axios';
  import Setting from '@/models/setting';
  import Form from '@/models/form';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/css/index.css';

  export default{
    name: 'TransactionClose',
    components: {
      Loading
    },
    mounted() {
      this.load(this.$route.params.id,this.$route.params.reference);
    },
    data: () => ({
      setting: new Setting(),
      form: new Form(),
      overlay: true
    }),
    methods: {

      async load(formid, formcode){
        try {
          await axios.get(this.setting.params.api + '/admin/landing/form/get/' + formid + '/' + formcode)
          .then(res => {
            let result = res.data;
            this.form.setdata(result.formulario[0]);
          });
          await axios.get(this.setting.params.api + '/admin/landing/form/cover/' + this.form.cover)
          .then(res => {
            const a = document.getElementById("transaction-banner-cover");
            a.style.backgroundImage = `url('${res.data}')`;
            this.overlay = false;
          });
        } catch (error) {
          this.setting.showerror(error);
        }
      }

    }
  }
</script>