import { createApp } from 'vue'
import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

//Imports pagkage
import { createWebHistory, createRouter } from 'vue-router'

//Components
import AccessDonants from './components/donants/access/AccessDonants.vue';
import DonantUpdate from './components/donants/access/DonantUpdate.vue';
import ValidateToken from './components/donants/access/ValidateToken.vue';
import DonantHome from './components/donants/home/DonantHome.vue';

import AccessManager from './components/manager/access/AccessManager.vue';
import ManagerDonant from './components/manager/donants/ManagerDonant.vue';
import ManagerUser from './components/manager/users/ManagerUser.vue';
import ManagerTemplate from './components/manager/templates/ManagerTemplate.vue';
import ManagerData from './components/manager/data/ManagerData.vue';
import ManagerLanding from './components/manager/landing/ManagerLanding.vue';
import ManagerForms from './components/manager/forms/ManagerForms.vue';
import ManagerRecurrences from './components/manager/recurrences/ManagerRecurrences.vue';
import AuthorizationAvn from './components/commons/AuthorizationAvn.vue';
import CertificateGenerate from './components/commons/CertificateGenerate.vue';

import ErrorPage from './components/commons/ErrorPage.vue';


import TransactionMain from './components/transactions/TransactionMain.vue';
import TransactionConfirm from './components/transactions/TransactionConfirm.vue';
import TransactionClose from './components/transactions/TransactionClose.vue';
import ManitenanceView from './components/maintenance/MaintenanceView.vue';

loadFonts()

const routes = [
  { path: '/maintenance', name: 'manitenance', component: ManitenanceView },
  { path: '/', name:'home', component: AccessDonants },
  { path: '/donante/actualizar', name:'donantupdate', component: DonantUpdate },
  { path: '/donante/validar', name:'donantvalidate', component: ValidateToken },
  { path: '/donante/inicio', name:'donanthome', component: DonantHome },
  { path: '/manager/access', name:'manageraccess', component: AccessManager },
  { path: '/manager/donants', name:'managerdonants', component: ManagerDonant },
  { path: '/manager/donants/authorization', name:'managerauthorization', component: AuthorizationAvn },
  { path: '/manager/users', name:'managerusers', component: ManagerUser },
  { path: '/manager/templates', name:'managertemplates', component: ManagerTemplate },
  { path: '/manager/importexport', name:'managerimportexport', component: ManagerData },
  { path: '/manager/landing', name:'managerlanding', component: ManagerLanding },
  { path: '/manager/landing/:referencia', name:'managerforms', component: ManagerForms },
  { path: '/manager/recurrent/pays', name:'managerrecurrences', component: ManagerRecurrences },
  { path: '/landing/search/:id/:reference', name: 'landingmain', component: TransactionMain },
  { path: '/landing/search/:id/:reference/closed', name: 'landingclosed', component: TransactionClose},
  { path: '/landing/confirm/transaction/:id?', name: 'landingtransaction', component: TransactionConfirm },
  { path: '/certificado/:year/:document:', name:'managercertificate', component: CertificateGenerate },
  { path: '/:pathMatch(.*)', name: 'errorpage', component: ErrorPage }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

createApp(App)
  .use(vuetify)
  .use(router)
  .use( CkeditorPlugin )
  .mount('#app')
